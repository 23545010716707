import React from 'react';

function PersonalAssistantSvg() {
  return (
    <svg
      width="406"
      height="394"
      viewBox="0 0 406 394"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <line
          opacity="0.2"
          x1="81.7016"
          y1="0.714966"
          x2="81.7016"
          y2="393.285"
          stroke="white"
        />
        <line
          opacity="0.2"
          x1="244.101"
          y1="0.714966"
          x2="244.101"
          y2="393.285"
          stroke="white"
        />
        <line
          opacity="0.2"
          x1="405.322"
          y1="0.714966"
          x2="405.322"
          y2="393.285"
          stroke="white"
        />
        <line
          opacity="0.2"
          y1="34.8538"
          x2="404.823"
          y2="34.8538"
          stroke="white"
        />
        <line
          opacity="0.2"
          y1="81.0381"
          x2="404.823"
          y2="81.0381"
          stroke="white"
        />
        <line
          opacity="0.2"
          y1="127.223"
          x2="404.823"
          y2="127.223"
          stroke="white"
        />
        <line
          opacity="0.2"
          y1="173.407"
          x2="404.823"
          y2="173.407"
          stroke="white"
        />
        <line
          opacity="0.2"
          y1="219.593"
          x2="404.823"
          y2="219.593"
          stroke="white"
        />
        <line
          opacity="0.2"
          y1="265.777"
          x2="404.823"
          y2="265.777"
          stroke="white"
        />
        <line
          opacity="0.2"
          y1="311.962"
          x2="404.823"
          y2="311.962"
          stroke="white"
        />
        <line
          opacity="0.2"
          y1="358.147"
          x2="404.823"
          y2="358.147"
          stroke="white"
        />
        <line
          opacity="0.2"
          x1="0.5"
          y1="0.714966"
          x2="0.5"
          y2="393.285"
          stroke="white"
        />
        <line
          opacity="0.2"
          x1="162.899"
          y1="0.714966"
          x2="162.899"
          y2="393.285"
          stroke="white"
        />
        <line
          opacity="0.2"
          x1="324.12"
          y1="0.714966"
          x2="324.12"
          y2="393.285"
          stroke="white"
        />
        <rect
          opacity="0.02"
          y="0.714966"
          width="81.2"
          height="392.57"
          fill="white"
        />
        <rect
          opacity="0.02"
          x="162.399"
          y="0.714966"
          width="81.2"
          height="392.57"
          fill="white"
        />
        <rect
          opacity="0.02"
          x="324.798"
          y="0.714966"
          width="81.2"
          height="392.57"
          fill="white"
        />
      </g>
      <g id="path-group">
        <path d="M206 199L276 291" stroke="#747474" strokeDasharray="4 4" />
        <circle cx="276" cy="297" r="34" fill="#413950" />
        <circle cx="276" cy="297" r="33.5" stroke="white" strokeOpacity="0.1" />
        <g clipPath="url(#clip0_2817_2974)" className="o-rotation">
          <path
            d="M263.045 309H267.818V296.636L261 291.182V306.818C261 308.025 261.917 309 263.045 309Z"
            fill="#4285F4"
          />
          <path
            d="M284.184 309H288.956C290.088 309 291.002 308.022 291.002 306.818V291.182L284.184 296.636"
            fill="#34A853"
          />
          <path
            d="M284.184 287.182V296.637L291.002 291.182V288.273C291.002 285.575 288.114 284.037 286.093 285.655"
            fill="#FBBC04"
          />
          <path
            d="M267.816 296.636V287.182L275.998 293.727L284.18 287.182V296.636L275.998 303.182"
            fill="#EA4335"
          />
          <path
            d="M261 288.273V291.182L267.818 296.637V287.182L265.909 285.655C263.884 284.037 261 285.575 261 288.273"
            fill="#C5221F"
          />
        </g>
        <path d="M206 199L88 155" stroke="#747474" strokeDasharray="4 4" />
        <circle cx="81" cy="155" r="34" fill="#413950" />
        <circle cx="81" cy="155" r="33.5" stroke="white" strokeOpacity="0.1" />
        <g clipPath="url(#clip1_2817_2974)" className="o-rotation">
          <path
            d="M72.4237 158.897C72.4237 160.622 71.03 162.016 69.3056 162.016C67.5812 162.016 66.1875 160.622 66.1875 158.897C66.1875 157.173 67.5812 155.779 69.3056 155.779H72.4237V158.897ZM73.9828 158.897C73.9828 157.173 75.3765 155.779 77.1009 155.779C78.8253 155.779 80.219 157.173 80.219 158.897V166.693C80.219 168.417 78.8253 169.811 77.1009 169.811C75.3765 169.811 73.9828 168.417 73.9828 166.693V158.897Z"
            fill="#E01E5A"
          />
          <path
            d="M77.1011 146.378C75.3767 146.378 73.983 144.984 73.983 143.26C73.983 141.535 75.3767 140.142 77.1011 140.142C78.8255 140.142 80.2192 141.535 80.2192 143.26V146.378H77.1011ZM77.1011 147.961C78.8255 147.961 80.2192 149.354 80.2192 151.079C80.2192 152.803 78.8255 154.197 77.1011 154.197H69.2822C67.5578 154.197 66.1641 152.803 66.1641 151.079C66.1641 149.354 67.5578 147.961 69.2822 147.961H77.1011Z"
            fill="#36C5F0"
          />
          <path
            d="M89.6 151.079C89.6 149.354 90.9937 147.961 92.7181 147.961C94.4425 147.961 95.8362 149.354 95.8362 151.079C95.8362 152.803 94.4425 154.197 92.7181 154.197H89.6V151.079ZM88.0409 151.079C88.0409 152.803 86.6472 154.197 84.9228 154.197C83.1984 154.197 81.8047 152.803 81.8047 151.079V143.26C81.8047 141.535 83.1984 140.142 84.9228 140.142C86.6472 140.142 88.0409 141.535 88.0409 143.26V151.079Z"
            fill="#2EB67D"
          />
          <path
            d="M84.9228 163.575C86.6472 163.575 88.0409 164.968 88.0409 166.693C88.0409 168.417 86.6472 169.811 84.9228 169.811C83.1984 169.811 81.8047 168.417 81.8047 166.693V163.575H84.9228ZM84.9228 162.016C83.1984 162.016 81.8047 160.622 81.8047 158.897C81.8047 157.173 83.1984 155.779 84.9228 155.779H92.7417C94.4661 155.779 95.8598 157.173 95.8598 158.897C95.8598 160.622 94.4661 162.016 92.7417 162.016H84.9228Z"
            fill="#ECB22E"
          />
        </g>
        <path d="M206 197L133 291" stroke="#747474" strokeDasharray="4 4" />
        <circle cx="130" cy="297" r="34" fill="#413950" />
        <circle cx="130" cy="297" r="33.5" stroke="white" strokeOpacity="0.1" />
        <g clipPath="url(#clip2_2817_2974)" className="o-rotation">
          <path
            d="M137.37 289.632H122.633V304.369H137.37V289.632Z"
            fill="white"
          />
          <path d="M137.367 311L143.999 304.368H137.367V311Z" fill="#EA4335" />
          <path
            d="M143.999 289.632H137.367V304.369H143.999V289.632Z"
            fill="#FBBC04"
          />
          <path
            d="M137.37 304.368H122.633V311H137.37V304.368Z"
            fill="#34A853"
          />
          <path
            d="M116 304.368V308.789C116 310.011 116.989 311 118.211 311H122.632V304.368H116Z"
            fill="#188038"
          />
          <path
            d="M143.999 289.632V285.211C143.999 283.989 143.01 283 141.788 283H137.367V289.632H143.999Z"
            fill="#1967D2"
          />
          <path
            d="M137.368 283H118.211C116.989 283 116 283.989 116 285.211V304.368H122.632V289.632H137.368V283Z"
            fill="#4285F4"
          />
          <path
            d="M125.656 301.063C125.105 300.691 124.724 300.148 124.516 299.429L125.794 298.903C125.91 299.345 126.113 299.687 126.402 299.931C126.689 300.174 127.039 300.293 127.448 300.293C127.866 300.293 128.226 300.166 128.526 299.912C128.826 299.658 128.977 299.334 128.977 298.941C128.977 298.54 128.819 298.212 128.502 297.958C128.185 297.703 127.787 297.576 127.312 297.576H126.573V296.311H127.236C127.645 296.311 127.99 296.2 128.27 295.979C128.55 295.758 128.69 295.456 128.69 295.071C128.69 294.728 128.565 294.456 128.314 294.251C128.064 294.047 127.747 293.944 127.362 293.944C126.986 293.944 126.687 294.043 126.466 294.244C126.246 294.445 126.079 294.699 125.984 294.983L124.718 294.456C124.886 293.981 125.194 293.561 125.645 293.198C126.096 292.835 126.673 292.652 127.373 292.652C127.89 292.652 128.356 292.752 128.769 292.953C129.182 293.153 129.506 293.432 129.74 293.785C129.974 294.141 130.09 294.539 130.09 294.981C130.09 295.432 129.981 295.813 129.764 296.127C129.546 296.44 129.279 296.679 128.962 296.847V296.922C129.372 297.091 129.727 297.368 129.99 297.724C130.257 298.083 130.392 298.512 130.392 299.013C130.392 299.514 130.265 299.962 130.011 300.354C129.756 300.747 129.405 301.056 128.959 301.281C128.511 301.506 128.008 301.62 127.45 301.62C126.804 301.622 126.207 301.436 125.656 301.063V301.063ZM133.509 294.719L132.105 295.734L131.403 294.669L133.921 292.853H134.887V301.421H133.509V294.719Z"
            fill="#4285F4"
          />
        </g>
        <path d="M206 198L205 69" stroke="#747474" strokeDasharray="4 4" />
        <circle cx="205" cy="69" r="34" fill="#413950" />
        <circle cx="205" cy="69" r="33.5" stroke="white" strokeOpacity="0.1" />
        <g clipPath="url(#clip3_2817_2974)" className="o-rotation">
          <path
            d="M205.227 60.5996V67.3196C205.227 67.734 205.555 68.07 205.96 68.07H212.527C212.527 66.0092 212.078 63.7692 210.754 62.414C209.429 61.0588 207.24 60.5996 205.227 60.5996Z"
            fill="#00832D"
          />
          <path
            d="M212.386 82.8659C209.322 83.3811 205.612 82.4515 202.055 80.2787L203.248 77.5571L205.951 76.2803L210.186 78.7555L212.386 82.8659Z"
            fill="#00832D"
          />
          <path
            d="M191.133 61.1152C190.629 64.2512 191.527 68.048 193.661 71.688L196.397 70.3776L197.568 67.6896L195.27 63.848L191.133 61.1152Z"
            fill="#00832D"
          />
          <path
            d="M206.321 55C205.719 55 205.227 55.504 205.227 56.12V60.6C209.254 60.6 212.527 63.9488 212.527 68.0704H216.904C217.506 68.0704 217.999 67.5664 217.999 66.9504C217.999 60.3536 212.767 55 206.321 55Z"
            fill="#34A853"
          />
          <path
            d="M216.96 80.2896C217.54 79.7184 217.562 78.7888 217.015 78.1952L211.74 72.4384C211.16 71.8784 210.262 71.8896 209.704 72.4608L205.961 76.28L212.385 82.8544C214.126 82.5968 215.428 81.8352 216.61 80.6256L216.96 80.2896Z"
            fill="#34A853"
          />
          <path
            d="M193.672 71.688C194.635 73.3232 195.915 74.9696 197.371 76.4592C198.827 77.9488 200.468 79.2816 202.066 80.2672L205.973 76.28L197.568 67.6896"
            fill="#34A853"
          />
          <path
            d="M200.381 64.9008L200.983 64.3072C201.694 63.5792 201.694 62.3808 200.983 61.6528L196.025 56.5792C195.314 55.8512 194.143 55.8512 193.431 56.5792L193.256 56.7024C192.107 57.8784 191.417 59.4016 191.133 61.1152L197.568 67.6896L200.381 64.9008Z"
            fill="#34A853"
          />
        </g>
        <path d="M206 198L321 155" stroke="#747474" strokeDasharray="4 4" />
        <circle cx="321" cy="155" r="34" fill="#413950" />
        <circle cx="321" cy="155" r="33.5" stroke="white" strokeOpacity="0.1" />
        <g clipPath="url(#clip4_2817_2974)" className="o-rotation">
          <path
            d="M321.013 175.978C332.605 175.978 342.002 166.581 342.002 154.989C342.002 143.397 332.605 134 321.013 134C309.421 134 300.023 143.397 300.023 154.989C300.023 166.581 309.421 175.978 321.013 175.978Z"
            fill="#1A73E8"
          />
          <path
            d="M328.649 164.055H314.347C312.313 164.055 310.538 162.519 310.538 160.485V160.463V161.653C310.538 163.687 312.313 165.462 314.346 165.462H328.649C330.705 165.462 332.458 163.666 332.458 161.653V160.463C332.458 162.497 330.683 164.055 328.649 164.055V164.055ZM310.517 153.799V152.176L307.855 147.783C307.704 147.545 307.639 147.329 307.66 147.112V148.973C307.66 149.146 307.725 149.32 307.833 149.514L310.517 153.799Z"
            fill="#185ABC"
          />
          <path
            d="M328.651 146.399H308.614C307.813 146.399 307.359 147.091 307.9 147.827L310.518 152.371V160.485C310.518 162.563 312.033 164.294 314.088 164.294H328.629C330.685 164.294 332.438 162.541 332.438 160.485V150.229C332.459 148.151 330.706 146.399 328.651 146.399V146.399Z"
            fill="white"
          />
          <path
            d="M328.41 152.133H314.583C313.977 152.133 313.393 151.765 313.393 151.181C313.393 150.597 313.977 150.229 314.583 150.229H328.41C329.015 150.229 329.6 150.597 329.6 151.181C329.6 151.765 329.015 152.133 328.41 152.133H328.41ZM328.41 155.941H314.583C313.977 155.941 313.393 155.573 313.393 154.989C313.393 154.405 313.977 154.037 314.583 154.037H328.41C329.015 154.037 329.6 154.405 329.6 154.989C329.6 155.573 329.015 155.941 328.41 155.941H328.41ZM324.58 159.771H314.561C313.955 159.771 313.371 159.403 313.371 158.819C313.371 158.235 313.955 157.867 314.561 157.867H324.58C325.186 157.867 325.77 158.235 325.77 158.819C325.77 159.403 325.186 159.771 324.58 159.771Z"
            fill="#8AB4F8"
          />
          <path
            d="M342.002 154.881C341.959 164.077 335.965 171.889 327.699 174.658C325.6 175.372 323.35 175.74 321.013 175.74C309.458 175.74 300.088 166.414 300.023 154.881V155.011C300.023 166.609 309.414 176 321.013 176C323.35 176 325.6 175.61 327.699 174.918C336.008 172.127 342.002 164.272 342.002 155.011V154.881Z"
            fill="#185ABC"
          />
          <path
            d="M327.675 135.082C325.577 134.368 323.326 134 320.989 134C309.391 134 300 143.391 300 154.989V155.119C300.065 143.586 309.434 134.26 320.989 134.26C323.326 134.26 325.577 134.649 327.675 135.342C335.963 138.112 341.935 145.923 341.978 155.119V154.989C342 145.728 336.006 137.874 327.675 135.082V135.082Z"
            fill="#8AB4F8"
          />
        </g>
      </g>
      <rect
        x="173"
        y="166"
        width="65"
        height="65"
        rx="6.42655"
        fill="#FF5A5F"
      />
      <path
        d="M204.455 198.053H194.4C193.136 198.053 192.105 199.086 192.105 200.351C192.105 201.613 193.137 202.649 194.4 202.649H202.162V211.168C202.162 212.431 203.196 213.465 204.456 213.465C205.717 213.465 206.751 212.431 206.751 211.168V200.351C206.75 199.086 205.717 198.053 204.455 198.053Z"
        fill="white"
      />
      <path
        d="M216.468 190.478V214.797C216.468 214.837 216.467 214.875 216.466 214.915C216.409 216.128 215.398 217.103 214.173 217.103C212.909 217.103 211.877 216.069 211.877 214.807V192.787H190.141C188.88 192.787 187.848 191.751 187.848 190.488C187.848 189.224 188.88 188.191 190.141 188.191H214.173C214.812 188.191 215.391 188.459 215.808 188.885L215.809 188.884C215.834 188.91 215.859 188.936 215.884 188.962C215.931 189.016 215.977 189.072 216.021 189.13C216.083 189.215 216.139 189.303 216.19 189.395C216.207 189.426 216.223 189.458 216.238 189.489C216.257 189.526 216.274 189.565 216.291 189.603C216.318 189.669 216.343 189.738 216.365 189.806C216.366 189.807 216.366 189.809 216.366 189.81C216.399 189.916 216.424 190.024 216.442 190.136V190.14C216.453 190.213 216.461 190.288 216.466 190.363C216.467 190.401 216.468 190.44 216.468 190.478Z"
        fill="white"
      />
      <path
        d="M226.758 219.269C226.758 219.309 226.757 219.347 226.755 219.387C226.693 220.597 225.686 221.567 224.462 221.567C223.438 221.567 222.562 220.884 222.27 219.95C222.249 219.88 222.23 219.81 222.217 219.739C222.208 219.702 222.202 219.665 222.195 219.627C222.178 219.514 222.169 219.397 222.169 219.279L222.12 183.087V182.898L186.232 182.946C184.97 182.946 183.938 181.914 183.938 180.649C183.938 179.387 184.97 178.351 186.232 178.351L224.411 178.3H224.437C225.644 178.3 226.641 179.247 226.724 180.436C226.726 180.454 226.727 180.472 226.727 180.491C226.728 180.527 226.732 180.562 226.732 180.598C226.732 180.643 226.706 180.68 226.704 180.725L226.758 219.269Z"
        fill="white"
      />
      <path
        d="M226.755 219.387C226.698 220.6 225.689 221.576 224.462 221.576C223.433 221.576 222.558 220.888 222.27 219.95C222.562 220.883 223.437 221.567 224.462 221.567C225.685 221.567 226.693 220.597 226.755 219.387Z"
        fill="white"
      />
      <path
        d="M202.164 202.638V211.159C202.164 212.421 203.196 213.454 204.459 213.454C205.719 213.457 206.754 212.421 206.754 211.159V200.34C206.754 199.685 206.47 199.102 206.029 198.683L202.078 202.638H202.164Z"
        fill="white"
      />
      <path
        d="M216.443 190.136C216.424 190.024 216.4 189.915 216.367 189.81C216.401 189.915 216.426 190.025 216.443 190.136Z"
        fill="white"
      />
      <path
        d="M216.469 190.363C216.464 190.288 216.457 190.213 216.445 190.14C216.457 190.213 216.465 190.288 216.469 190.363Z"
        fill="white"
      />
      <path
        d="M222.167 219.278C222.167 219.396 222.176 219.513 222.193 219.626C222.174 219.509 222.164 219.39 222.164 219.268L222.117 183.086L222.167 219.278Z"
        fill="white"
      />
      <defs>
        <clipPath id="clip0_2817_2974">
          <rect
            width="30"
            height="24"
            fill="white"
            transform="translate(261 285)"
          />
        </clipPath>
        <clipPath id="clip1_2817_2974">
          <rect
            width="30"
            height="30"
            fill="white"
            transform="translate(66 140)"
          />
        </clipPath>
        <clipPath id="clip2_2817_2974">
          <rect
            width="28"
            height="28"
            fill="white"
            transform="translate(116 283)"
          />
        </clipPath>
        <clipPath id="clip3_2817_2974">
          <rect
            width="27"
            height="28"
            fill="white"
            transform="translate(191 55)"
          />
        </clipPath>
        <clipPath id="clip4_2817_2974">
          <rect
            width="42"
            height="42"
            fill="white"
            transform="translate(300 134)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PersonalAssistantSvg;
